import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      light: '#484997',
      main: '#ff6200',
      dark: '#00003d',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffcf50',
      main: '#fc9e15',
      dark: '#c36f00',
      contrastText: '#000'
    },
    white: {
      main: '#FFFFFF'
    }
  },

  typography: {
    fontSize: 16,
    title: {
      fontSize: 15
    },
    subTitle: {
      fontSize: 13
    }

  }
}))

export default theme
