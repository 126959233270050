import React, { useCallback, useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import '@szhsin/react-menu/dist/index.css';
import logo from "../../assets/images/akasa.svg";
import { Badge, Box, Button, FormHelperText, TextField, FormControl, Typography, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink, useHistory } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import googleico from '../../assets/images/search.png';
import jwt_decode from "jwt-decode";
import './header.css'
import { emailValidator, passwordValidator } from '../user/validation';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { loginRequest } from '../../store/user/actions/loginActions';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';

const SignIn = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { isLoading, userDetails } = useSelector(state => state.user)
    const [opensignmodal, setOpensignmodal] = useState(false);
    const [username, setUsername] = useState({ value: '', error: '' })
    const [password, setPassword] = useState({ value: '', error: '' })
    const [captcha, setCaptcha] = useState({ value: '', error: '' });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setOpensignmodal(false)
    }
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        billing_email: null,
    });
    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };


    const handleSignOpen = (e) => {
        e.preventDefault()
        setOpensignmodal(true);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRegister = event => {
        console.log("name", event.target.name, "value", event.target.value)
        //error[event.target.name] = ''
        //setRegisters({...registers, [event.target.name] : event.target.value}) 
    };
    function handleCallbackresponse(response) {
        console.log("Google Response", response.credential)
        let credential = response.credential;
        var decoded = jwt_decode(credential);
        console.log("Decoded output", decoded)
    }

    useEffect(() => {
        loadCaptchaEnginge(6, '#ebeefa', 'black', "upper")
    }, [])

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: "18242223289-mndpofq0263cb289suesrrlo82j2ir8u.apps.googleusercontent.com",
                callback: handleCallbackresponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById("logingoogle"),
                { theme: "outline", size: "large" }
            );
        }
    }, []);


    const handleLoginSubmit = (e) => {
        let user_captcha = captcha.value;
        //console.log'handleLoginSubmit=============>');
        e.preventDefault();
        const usernameError = emailValidator(username.value);
        const passwordError = passwordValidator(password.value);

        if (usernameError || passwordError) {
            setUsername({ ...username, error: usernameError });
            setPassword({ ...password, error: passwordError });
            return;
        }

        if (captcha.value === '') {
            setCaptcha({ ...captcha, error: 'Enter above captcha' });
            return
        }
        if (validateCaptcha(user_captcha) == true) {
            // alert('Captcha Matched');
            const data = {
                username: username.value,
                password: password.value
            }

             dispatch(loginRequest(data, history));
            loadCaptchaEnginge(6, '#ebeefa', 'black', "upper")
            setCaptcha({ ...captcha, value: '', error: '' });
        }

        else {
            alert('Captcha Does Not Match');
            setCaptcha({ ...captcha, value: '' });
        }
    }

    return (
        <div className='inputsect_login'>
            <img src={userDetails && userDetails.logo ? PROFILE_IMAGE + userDetails?.logo : logo} className="loginlogo" alt="logo" />
            <div className='textBoxlogin'>
                <label className=''>Email Address</label>

                <TextField
                    className='emailfield'
                    variant='outlined'
                    autoFocus
                    margin="dense"
                    id="name"
                    placeholder="Email Address"
                    type="email"
                    fullWidth
                    error={!!username.error}
                    helperText={username.error}
                    value={username.value}
                    name="username"
                    onChange={(e) => setUsername({ value: e.target.value, error: '' })}
                />
                

                <label className='mt-3 mb-2'>Password</label>
                <FormControl sx={{  width: '100%' }} variant="outlined">
                    <OutlinedInput
                    className='emailfield'
                    id="outlined-adornment-password"
                    type={showPassword1 ? 'text' : 'password'}
                    error={!!password.error}
                    helperText={password.error}
                    value={password.value}
                    name="password"
                    onChange={(e) => setPassword({ value: e.target.value, error: '' })}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            edge="end"
                        >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                    />
                    <FormHelperText id="component-helper-text" className='text-danger'>
                    {password.error}
                    </FormHelperText>
                </FormControl>
                {/* <TextField
                    className='emailfield'
                    variant='outlined'
                    autoFocus
                    margin="dense"
                    id="name"
                    placeholder="Password"
                    type="password"
                    fullWidth
                    error={!!password.error}
                    helperText={password.error}
                    value={password.value}
                    name="password"
                    onChange={(e) => setPassword({ value: e.target.value, error: '' })}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                                >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                /> */}

                <div className="captchaSection" >
                    <LoadCanvasTemplate reloadText=" " />
                    <img
                        src="https://itwgo.in/agentAPI/extras/custom/TMX1512291534825461/images/reload.png"
                        alt="new"
                        className="captchaImg"
                        onClick={() => loadCaptchaEnginge(6, '#ebeefa', 'black', "upper")} />
                </div>
                <TextField
                    className='emailfield'
                    variant='outlined'
                    margin="dense"
                    id="captcha"
                    placeholder="Enter above captcha"
                    fullWidth
                    error={!!captcha.error}
                    helperText={captcha.error}
                    value={captcha.value}
                    name="captcha"
                    onChange={(e) => setCaptcha({ value: e.target.value, error: '' })}
                />
            </div>
            <div className='btnpostion'>
                <div className='forgot-password'>
                    <span onClick={handleShow}>Forgot Password?</span>
                </div>
                <Button className='Btnlogin' onClick={handleLoginSubmit} variant='contained' color='primary'>Login</Button>
                <div className='ttext'>Or</div>
                    {/* <Button className='Btnlogin' startIcon={<DeleteIcon />} variant='contained' color='primary'>Login</Button> */}
                    <div className='logingoogle' id="logingoogle">
                        {/* <img src={googleico} className="img_with" /><span>Login with Google</span> */}
                    </div>
               
                <div className='signup-text'>
                    <a href="/">Don't have an account? <Button onClick={handleSignOpen}>Create One </Button> </a>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Box><h3 className="subtlt-popup">Enter registered Email ID</h3></Box>
                        <Box>
                            <TextField
                                className="inputforgot"
                                variant="outlined"
                                placeholder="Enter Email ID"
                                // error={!!forgotemail.error}
                                // helperText={forgotemail.error}
                                // value={forgotemail.value}
                                name="forgotemail"
                                // onChange={(e) => setForgotemail({ value: e.target.value, error: '' })}
                            ></TextField>
                        </Box>
                        <Box>
                            <h3 className="subtlt-popup">Enter registered mobile number</h3>
                        </Box>
                        <Box>
                            <TextField
                                className="inputforgot"
                                variant="outlined"
                                placeholder="Enter Mobile number"
                                // error={!!forgotphoneno.error}
                                // helperText={forgotphoneno.error}
                                // value={forgotphoneno.value}
                                name="forgotphoneno"
                                inputProps={{ maxLength: 10, }}
                                // onChange={(e) => setForgotphoneno({ value: e.target.value, error: '' })}
                            ></TextField>
                        </Box>
                        <Typography className="smalltxt">
                            New password will be sent once your details are verified
                        </Typography>
                </Modal.Body>
                <Modal.Footer>
                <Button className='btn btn-primary' color='success' variant="contained" onClick={handleClose}>
                    Submit
                </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}

export default SignIn